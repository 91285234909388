
  const mailBody = `
  ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⡟⠀⢠⠇⣼⠀⠀⠀⢀⡴⠋⠁⢀⡤⣄⣀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢿⡌⢧⡛⣼⣩⠯⣝⡝⣮⠽⣭⢯⡹⣭⢏⡽⣍⠯⣝⡭⣫⢭⢏⡽⣩⢏⡝⣮⢣⢏⡭⣑⢚⡩⢌⡁⠀⠀⠀⠀⠀⠀⢠⡱⢎⡗
  ⠀⠀⠀⠀⠀⠀⠀⠀⢤⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣀⡤⠤⠒⠒⠒⠒⠀⠈⠉⠉⢑⣺⠷⠓⠢⢤⣴⣋⣀⣠⡞⢕⠲⢌⢢⡙⢶⡀⠀⠀⠀⠀⠀⠀⠈⣏⠧⣝⡲⢧⡻⣜⡞⣧⢻⡜⣶⢻⡜⣮⢳⢞⡽⣺⡜⣧⣛⢮⡳⣝⠾⣹⢖⢯⡞⣲⢓⡞⡴⣊⠖⣡⠀⠀⠀⠀⢀⠶⣙⢮⡝
  ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⢄⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⡠⠔⠊⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠐⠋⠀⠀⢀⡴⠋⠀⠀⠀⠉⠓⠮⢼⣬⠶⢞⠫⡝⢫⣔⠒⠒⠒⠦⣄⣏⡞⡼⢭⡳⣝⢮⡝⣮⢳⡝⣮⢳⡝⣮⣛⠮⣵⢣⢟⡲⣝⢮⡳⢭⣏⠷⣎⢷⣚⡥⢯⡜⣥⠹⡜⡤⢃⠀⡠⣐⠮⡽⣙⢮⡝
  ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣀⡥⠤⠤⠤⠄⠤⠠⠐⠶⠛⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠉⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠙⠾⣔⡩⠖⣌⠳⡀⠀⠀⢨⡗⢮⣙⢧⡻⣜⢧⣻⡜⣧⢻⡜⣧⣛⢶⡹⢞⡱⣏⢾⡱⣏⡞⣭⢳⣎⢟⡼⢣⢮⣝⡲⡝⢦⡫⣕⢎⠲⡌⡕⢮⡹⡜⢧⡳⣝
  ⠀⠀⠀⠀⠀⢀⡠⠔⠚⠉⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠳⢭⣢⣓⣹⣆⣠⣾⣑⠎⣜⢧⡻⣜⢧⡳⣝⢮⣳⡝⣶⡹⢮⡝⣯⢳⡝⣮⢳⠽⣸⢧⡳⣎⠯⣞⡝⢶⢪⣕⡫⢞⡱⣎⢎⡳⣜⡹⢦⡝⣹⢇⡻⣜
  ⠤⣀⣀⠴⠊⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠉⠁⠀⠀⠀⠀⠈⡝⠺⣧⣟⣞⢧⡻⣜⢧⡳⣝⢶⡹⢧⡻⣜⢧⡻⣜⠯⣝⡳⣎⠷⣭⡛⢶⡹⢎⡷⣌⢯⢳⣱⡚⣬⠳⣌⠳⣎⠼⣣⢏⡵⣞
  ⣀⠀⠙⢦⠀⠀⠀⠀⠀⣀⠤⠖⠊⠉⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⢻⣎⢧⣛⡞⣧⢟⣮⢳⡝⣧⢻⡜⣧⡻⣜⡻⣜⡳⣭⣛⢶⡹⢧⣏⢏⡶⡹⣎⢧⣣⢝⠶⡹⣌⠳⣌⢳⡱⢎⡷⣭
  ⠀⠙⠢⣄⠳⢄⣀⡤⠚⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠠⠤⠤⣄⡀⠀⢻⣏⢾⡹⣎⠿⣜⢧⡻⣜⢧⣛⢶⡙⢧⡻⣜⡳⡵⣹⢎⡳⣏⡼⢮⣱⢛⡼⢎⡵⢪⣍⣵⣈⠳⡌⢧⣹⡹⣞⡵
  ⠀⠀⠀⠈⠙⢮⣿⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢤⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠑⢮⣧⣏⡳⣭⢻⡜⢧⣛⡼⢣⡝⢮⣱⣷⣷⣌⡳⣹⢬⢏⡳⢮⢵⣣⢏⡾⣱⢏⣼⡟⣍⢻⣿⣷⡌⢳⠦⣝⡾⡽
  ⠀⠀⠀⠀⠀⠀⠙⢷⠖⠒⠋⠉⠉⠉⠉⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠘⢳⣤⠀⠀⠀⠀⠀⠀⠀⣀⠔⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠹⣆⠉⣏⢳⡚⢧⡳⣜⢣⡝⣆⡿⡌⣿⣿⣷⡵⢎⢯⡹⢧⡳⣭⢎⡷⢱⡾⣣⢞⡼⣹⣿⣿⣿⣬⢓⡬⣳⣛
  ⠒⠢⢤⡀⠀⠀⠀⠈⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢩⠟⣦⣄⠀⠀⢀⠔⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠐⠒⠦⣄⠀⠀⠹⡄⠟⠶⢛⡷⡳⣜⣣⠞⣼⢳⢯⡜⣿⣿⣿⣯⣞⡹⢧⣻⡱⢏⣼⢟⣱⡹⢎⡶⣹⣿⣿⣿⣧⠀⠀⢩⣿
  ⠀⠀⠀⠈⠓⢤⡀⠀⣹⠒⠒⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠚⠋⠙⢯⣝⣛⢶⡴⠋⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠑⢤⡀⡇⠀⠀⣯⢱⡙⠦⠃⠏⣿⡾⣓⢮⣹⣿⣿⣿⣿⣭⣳⢧⢝⡾⣏⠞⣴⣋⢧⢳⣹⣿⣿⣿⣿⡆⠀⣸⣿
  ⠒⠢⢄⣀⠀⠀⠙⢦⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠳⢾⣧⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠳⣟⠀⠀⠙⠢⣀⠀⡰⠊⡧⣿⡩⣖⢣⣿⣿⣿⣿⣿⣿⣀⡾⣳⠼⣹⠦⣏⠞⡵⣺⣿⣿⣿⣿⣷⠀⠈⠀
  ⠀⠀⠀⠈⠱⢆⠀⢸⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠉⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⡀⠀⠀⠀⠀⠀⠀⠀⡷⣇⢷⡸⢇⣾⣿⣿⣿⣿⣿⣿⣷⡉⣏⢱⠿⣈⠿⣱⣿⣿⣿⣿⣿⣿⠀⠀⠀
  ⢂⠖⡠⢤⡀⠀⠙⢾⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣀⣠⠤⣄⠀⠀⣠⣴⣶⡶⢤⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣠⠀⠀⠀⢳⠀⠀⠀⠀⠀⠀⢀⣿⠰⣣⣽⣾⣿⣿⣿⣿⣿⣿⣿⣿⣿⣮⣗⢭⡝⢮⣱⣿⣿⣿⣿⣿⣿⠀⠀⠀
  ⢚⠖⡳⠶⠧⣥⣀⡏⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⡠⢞⣓⣒⠶⠂⠉⠋⠉⠀⠀⠀⠀⢹⡀⠀⠀⠀⠀⠀⢸⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠸⣄⠀⠀⢸⠀⠀⠀⠀⠀⡔⠁⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣷⣾⣣⣾⣿⣿⣿⣿⣿⣿⡀⠀⠀
  ⡃⢎⡱⢡⠓⡌⡿⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢠⠃⠀⠀⠀⠀⠀⢀⡞⠋⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢨⠇⠀⠀⠀⠀⠀⢸⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢿⣆⠀⢸⠀⠀⠀⠀⠀⢡⣾⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣧⠀⠀
  ⡑⢎⡐⡃⢎⣼⠃⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⠀⠀⠀⠀⠀⠀⠀⠀⢠⡏⠀⠀⠀⠀⠀⠀⢸⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣸⠀⠀⠀⠀⣀⠄⡟⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⢻⡄⡞⠀⠀⠀⠀⢀⣾⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡆⠀
  ⡘⢆⡱⣑⡾⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⡏⠀⠀⠀⠀⠀⠀⠀⢀⡏⡇⠀⠀⠀⠀⠀⠀⢸⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣰⠃⠀⠀⢀⡞⢁⣼⢇⡆⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣸⣏⡟⠁⠀⠀⠀⢠⣾⣿⣿⣿⣏⠛⢛⣽⣿⣿⣿⠿⠿⠿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⠀
  ⢘⣴⠞⡫⠄⠀⠀⠀⠀⠀⢀⠀⠀⠀⠀⢸⠁⠀⠀⠀⠀⠀⠀⠀⢸⠁⢳⡀⠀⠀⠀⠀⠀⠘⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠤⣤⡞⠁⠀⠀⣠⣯⠔⢋⣾⠟⠀⠀⠀⣠⢂⣄⠀⠀⠀⠀⢰⠏⣿⠀⠀⠀⠀⠀⣸⡟⢉⣽⣿⣿⣿⣿⣿⣿⣿⡶⣦⡄⠀⠈⢿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡆
  ⢋⠴⠩⡔⢡⠀⠀⠀⠀⠀⡏⠀⠀⠀⠀⢸⡀⠀⠀⠀⠀⠀⠀⠀⢸⠈⠀⠳⣄⠀⠀⠀⠀⠀⠘⢦⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠉⠉⢓⡞⣉⣳⣶⣿⠿⠤⣤⡴⢋⣠⠞⢹⠀⠀⠀⣡⠟⢪⢹⡄⠀⠀⠀⠀⣸⢃⣿⣿⣿⣿⣿⣿⣿⣿⠯⡷⣏⣿⠀⠀⢸⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡇
  ⠎⡜⣡⠜⡡⠀⠀⢠⠀⠀⡇⠠⡀⠀⠀⠘⣇⠀⠀⠀⠀⠀⠀⠀⠸⡆⠀⠀⠈⠑⠦⠤⢤⡤⡤⠤⠛⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣾⠋⣵⣿⣿⣿⣷⡶⢿⣷⠏⠀⠀⢸⠀⢀⡴⢏⡜⠧⣊⣷⠀⠀⠀⢠⣿⢸⣼⣿⣿⣿⣿⣿⣿⠃⠀⣿⣭⢿⠀⠀⢸⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⠇
  ⢣⠜⡰⢊⡕⡀⠀⢸⢆⠀⢷⡀⣇⠀⠀⠀⢻⢦⡀⠀⣀⣀⣀⣀⣄⣽⣤⡀⠀⠀⠀⠀⠀⠀⠀⠀⡀⠀⠀⢀⠀⠀⠀⠀⠀⠀⠀⢹⠃⣸⣿⣿⠏⠹⡿⢷⠋⠁⠀⠀⢀⣏⣴⠛⣍⠲⣘⢲⠉⠈⢧⠀⣴⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡀⢀⣿⣾⠏⠀⢀⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣧
  ⡃⢎⡱⢡⠜⡘⢆⡱⢊⠧⡈⣷⡹⣄⠀⠀⠈⢦⠝⠛⠒⠒⠒⠒⠉⠀⣠⠽⣛⣒⣖⣦⠤⠤⣤⠼⠥⠒⠒⠃⠀⠀⠀⠀⠀⠀⠀⠀⠀⣿⣿⣿⠀⠀⡇⡾⠀⠀⢀⡤⠛⣹⢻⡉⣤⠓⡬⡟⠀⠀⠸⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣆⠘⣀⣴⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
  ⡍⢦⡑⢣⠊⡕⢪⠔⣣⢊⡕⡘⣯⡛⠓⠤⠄⠈⡷⠄⠀⠀⠀⢀⡴⣫⡵⢿⣿⣿⣿⣿⣿⣾⡥⠤⠚⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠰⣿⣿⣿⡀⢠⣇⡧⠴⠚⠉⠀⠀⢸⡛⣧⢌⠣⣇⡇⠀⠀⠀⢿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
  ⡜⢢⡙⠦⡙⣌⢣⢚⡰⢌⠦⡑⢦⢙⠦⣀⣀⡄⠐⢦⡀⠀⡠⣫⠞⠉⢀⣿⣿⣿⣿⠋⠈⢻⡄⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⡟⢻⣿⣿⣿⣿⠁⠀⠀⠀⠀⠀⠘⣇⢹⡎⡱⢬⡇⠀⠀⠀⠘⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
  ⢎⡱⢌⢣⠱⡌⢆⢣⠻⣮⣒⣩⣂⣍⠶⠛⠁⠀⠀⠀⠈⠿⣻⠋⠀⠀⣸⣿⣿⣿⣿⠀⠀⠀⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠁⢈⣿⣯⢿⣿⠀⠀⠀⠀⠀⠀⠀⣿⢠⢿⡑⣲⠁⠀⠀⠀⠀⢹⡙⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
  ⠦⡑⢎⢆⠳⡘⢎⢆⠳⣈⠷⢭⣁⣀⣀⡤⠀⠉⠒⠂⠀⢶⠃⠀⠀⠀⣿⣿⣿⣿⣿⠀⠀⠀⣿⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢰⣼⣿⣿⡾⠈⣇⠀⠀⠀⠀⠀⠀⣿⢸⡌⢷⣩⠂⠀⠀⠀⠀⠀⢧⠈⠿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
  ⢣⡙⢆⠎⢥⣉⣬⠮⠼⣥⡮⠖⠛⠋⠁⠀⠠⠤⠤⠤⠤⣞⠀⠀⠀⠀⢻⣿⣿⣿⣿⣧⣤⣼⣿⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⠀⠀⠀⠻⣿⣿⣁⠀⠘⡆⠀⠀⠀⠀⠠⢼⡒⡜⡌⣷⡀⠀⠀⠀⠀⠀⠈⣇⠀⠈⠙⠿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
  ⠣⡜⢢⢩⡖⠉⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠉⠀⠀⠀⠀⠈⢿⠉⠙⢿⣿⣿⣿⣿⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠸⣄⡀⠀⠀⠀⠈⣀⣄⠀⡇⠀⠀⠀⠀⠀⠘⣟⠦⠜⡘⣧⠀⠀⠀⠀⠀⠀⠸⡏⠉⠉⠀⠀⠈⠉⠉⠛⠛⠛⠛⠟⠟⠻⠛⠛⠛⠛⠛⠻⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
  ⠱⡌⢥⡏⠀⢀⠤⠤⢄⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⢀⣀⣾⣿⣿⣯⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢠⡇⠀⠀⠀⠀⠈⠀⠀⡇⠀⠀⠀⠀⠀⠀⢹⡘⢧⢡⢹⡆⠀⠀⠀⠀⠀⠀⠹⡄⠈⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠻⣿⣿⣿⣿⣿⣿⣿⣿⣿
  ⠳⢮⣼⠀⠀⠋⠀⠀⠀⡸⠒⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠘⢿⣿⣿⣿⣿⡧⠄⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠘⠁⠀⠀⠀⠀⠀⠀⢀⠇⠀⠀⠀⠀⠀⠀⠸⣇⠎⢦⢃⢷⠀⠀⠀⠀⠀⠀⠀⢻⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠸⣿⣿⣿⣿⣿⣿⣿⣿
  ⠀⠀⠉⢷⠀⠀⠀⠀⠀⠙⢤⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠉⠙⠀⠀⢀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⡼⠀⠀⠀⠀⠀⠀⠀⠀⢻⡨⢍⠎⡜⣧⠀⠀⠀⠀⠀⠀⠈⣇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠹⣿⣿⣿⣿⣿⣿⣿
  ⠀⠀⠀⠈⢷⡀⠀⠀⠀⠀⠀⠈⡆⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠒⠈⠓⠀⠀⠀⠀⠀⠀⣀⠤⠤⠤⠀⠀⠀⠀⠀⠀⠀⡰⠁⠀⠀⠀⠀⠀⠀⠀⠀⠈⣗⢮⡙⢤⡙⡆⠀⠀⠀⠀⠀⠀⠘⡆⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠘⣿⣿⣿⣿⣿⣿
  ⠀⠀⠀⠀⠀⠙⢦⡀⠀⠀⠀⢠⣷⡄⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⡜⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢹⡆⡝⠦⡱⠸⡄⠀⠀⠀⠀⠀⠀⢹⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⢻⣿⣿⣿⣿
  ⠀⠀⠀⠀⠀⠀⠀⠉⠓⠲⠤⠤⣭⡤⢤⣀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢠⠎⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢷⣘⡱⠼⡄⢹⡀⠀⠀⠀⠀⠀⠀⢳⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣰⣿⣿⣿⣿
  ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢼⡇⠀⠈⠑⠲⣤⣀⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⡴⡃⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠘⣧⠍⣎⡇⠀⢣⠀⠀⠀⠀⠀⠀⠀⢧⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣰⣿⣿⣿⣿⣿
  ⣠⣶⣶⢶⣶⣶⢤⣤⣀⣀⠀⠀⠘⠃⠀⠀⠀⠀⢹⠀⠈⠉⠒⠦⢤⣀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣴⣋⣠⣤⣤⣴⠖⣢⣾⣿⢿⣶⣦⣄⠀⠀⠀⠀⠀⢹⡼⣂⡇⠀⠈⣇⠀⠀⠀⠀⠀⠀⠈⣆⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣠⣾⣿⣿⣿⣿⣿⣿
  ⠸⣿⣿⢈⣿⣿⡌⣿⣿⢿⣿⣿⣶⣦⣤⣀⣀⣀⣸⡄⠀⠀⠀⠀⠀⠀⠉⠙⠒⠢⠤⢄⣀⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣀⣤⣾⣿⠏⣴⣿⣿⡿⢁⣾⣿⣿⣽⠿⠚⠛⠛⢧⡄⠀⠀⠀⠈⣧⢒⡟⠀⠀⠘⡄⠀⠀⠀⠀⠀⠀⠸⡄⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣀⣤⣾⣿⣿⣿⣿⣿⣿⣿⣿
  ⠀⣿⣿⡀⣿⣿⡇⣿⣿⣻⣷⣻⣽⣟⡿⣿⢿⣿⣿⣧⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠉⠁⢲⣶⣶⣶⣶⣶⣾⣿⣿⣿⣿⢋⣼⣿⣿⠏⢠⣿⣿⣯⠟⠀⠀⠀⠀⠀⠀⠈⡳⣄⠀⠀⢸⣎⡇⠀⠀⠀⠹⡄⠀⠀⠀⠀⠀⠀⢳⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢠⣾⣿⣿⣿⣿⣿⣿⣿⣿⣿⡿⠛⠁
  ⠀⢸⣿⡇⣿⣿⣿⢸⣿⡿⣾⣹⣷⢿⣹⣏⣿⣏⣿⢿⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⣿⣿⣿⢿⣹⣿⢿⣏⣿⢇⣾⣿⣿⠇⢰⣿⣿⡿⠁⠀⠀⠀⠀⠀⠀⣀⠎⠀⠀⢱⡶⠶⣿⠀⠀⠀⠀⠀⢷⠀⠀⠀⠀⠀⠀⠀⣷⠀⠀⠀⠀⠀⠀⠀⠈⠉⠉⠈⠉⠉⠉⠹⠿⠉⠉⠉⠀⠀⠀⠀
  ⠀⢸⣿⡇⢹⣿⣽⡜⣿⣿⣻⣽⣟⡿⣯⣿⢾⣿⡟⢸⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣠⣿⣿⢿⣽⣻⣯⡿⣿⣿⢏⣾⣿⣿⠃⣠⣿⣿⠞⠁⠀⠀⠀⠀⢀⠠⢎⠉⢆⠀⠀⠀⣇⠀⢸⡆⠀⠀⠀⠀⠈⣇⠀⠀⠀⠀⠀⠀⠸⡄⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
  ⠀⠀⣿⣷⢸⣿⡿⡇⣿⣿⣽⣳⣟⣿⣽⣾⡿⣿⠀⠠⠇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣴⣿⣿⡿⣟⣯⣷⢿⣻⣿⢯⣿⣿⡿⠁⣴⣿⣿⠋⠀⠀⠀⠀⡀⠐⢻⡀⠀⠓⢬⡇⠀⠀⢸⡀⠀⡇⠀⠀⠀⠀⠀⠸⡄⠀⠀⠀⠀⠀⠀⢧⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
  ⠀⠀⣿⣿⠘⣿⣿⣷⢹⣿⡾⣿⣽⣻⣾⣳⣿⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣠⣿⣿⡿⣯⢿⡿⣽⣿⢿⡿⣳⣿⣿⠟⢁⣼⣿⡿⠁⠀⠀⢀⠤⠊⠀⠀⠀⠇⠀⠀⠀⠀⠀⠀⠀⢣⣀⣹⠀⠀⠀⠀⠀⠀⢳⠀⠀⠀⠀⠀⠀⠈⣧⣀⣀⣀⣀⣀⣀⣀⣀⣀⣀⣀⣀⣀⣀⣀⣀⣀⣀⣀⣀⣀
  ⠀⠀⢹⣿⡆⣿⣿⢿⡘⣿⣟⣷⡿⣽⡷⣿⣟⡟⢄⠀⠀⠀⠀⠀⠀⠀⠀⢀⡠⠔⣺⣿⣿⣿⢿⣽⣟⣿⣻⣿⣻⢟⣴⣿⣿⠏⣠⣿⣿⡟⠀⠀⢀⠔⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠸⡇⠘⣇⠀⠀⠀⠀⠀⠈⡇⠀⠀⠀⠀⠀⠀⢹⠐⠀⠐⡀⣀⠠⠀⢄⠠⢀⠰⠀⠄⡈⠠⢁⠈⠤⠐⠠⡁
  ⠀⠀⢸⣿⣇⠸⣿⣿⡇⢿⣿⣯⣿⢿⣽⣻⣿⠇⠈⠃⠀⠀⠀⠀⠀⠀⠀⠉⢀⣼⣿⣿⡿⣽⣟⣿⣾⢿⣟⡿⣫⣿⣿⡿⢁⣴⣿⣟⡿⠁⢀⠔⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⡇⠄⢻⡀⠀⠀⠀⠀⠀⢹⡀⠀⠀⠀⠀⠀⠸⡇⠈⡅⠂⢄⠂⠍⡠⠒⡈⠤⢉⡐⠠⠑⡂⠘⡠⢉⠔⡠
  ⠀⠀⢸⣿⣿⡄⢻⣿⣿⠸⣿⣷⡿⣯⣟⣿⣽⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣠⣿⣿⣿⢯⣿⣟⣾⣟⣯⢟⣭⣾⣿⠿⠋⣠⣾⣿⣯⡟⢀⠔⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣿⢨⠈⡇⠀⠀⠀⠀⠀⠀⣇⠀⠀⠀⠀⠀⠀⣧⠡⢈⠔⡈⢄⢃⡐⠡⡐⠤⡁⠤⠑⠢⢌⠡⡐⢢⠘⡠
  ⡀⠀⠘⣿⡿⣿⡀⢿⣿⣇⢻⣿⣿⣻⣽⡿⣿⠀⠀⠀⠀⠀⠀⠀⠀⢀⣾⣿⣿⡿⣯⣿⣿⣾⠟⣫⣴⣿⡿⠟⢁⣤⣾⣿⣟⣷⢟⠔⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣾⠘⣠⢻⠀⠀⠀⠀⠀⠀⢸⡀⠀⠀⠀⠀⠀⢸⡐⠄⡊⠔⢂⠆⢌⠂⡅⢒⡈⠤⢉⠒⠤⡑⢠⠁⢆⠡
  ⣿⣻⢶⣿⣿⣿⣿⡜⣿⣿⣎⢿⣿⣟⣷⣿⣿⠀⠀⠀⠀⠀⠀⠀⣰⣿⣿⣿⢿⣿⡿⢟⣩⣶⣿⣿⠟⠋⢀⣴⣿⣿⣿⣟⣾⠫⠊⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⡿⠀⠫⠘⡇⠀⠀⠀⠀⠀⠀⣇⠀⠀⠀⠀⠀⢸⡇⠤⢁⠎⡄⢊⠤⡑⡐⢂⡘⢠⠃⡘⠄⠱⢠⢉⠢⢡
  ⡷⢯⣳⢯⣽⣻⢿⣿⣮⡻⣿⣷⡿⣿⣿⣳⣿⠀⠀⠀⠀⠀⢀⣼⣿⣿⡿⠞⣋⣵⣾⣿⣿⠿⠋⢀⣤⣾⣿⣿⣿⣻⣽⣞⡕⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⡗⠠⢁⠠⣷⠀⠀⠀⠀⠀⠀⠸⡀⠀⠀⠀⠀⠀⡇⠢⢁⠒⠌⡂⢆⠡⢂⠅⡒⢠⠊⠔⡉⢆⠡⡂⠜⡠
  ⣟⣯⢯⣟⡶⢯⣻⡽⣿⣷⡙⢿⣿⣮⡻⢿⡏⠀⠀⠀⠀⢀⡾⠟⣋⣥⣶⣿⣿⣿⠿⢋⣡⣴⣾⣿⣿⣿⡿⣷⣟⡿⣽⠏⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⡇⡐⠠⡁⢸⡀⠀⠀⠀⠀⠀⠀⣇⠀⠀⠀⠀⠀⣿⢀⠣⠘⠤⠑⡈⢆⠡⢂⢅⠣⠘⠤⡑⡈⢆⠡⢃⡔
  ⣟⡾⣽⠾⣽⢯⣗⣟⣳⢿⣿⣦⣝⢿⣿⣷⣷⠀⠀⠀⣠⣿⣶⣿⣿⡿⠟⣋⣡⣴⣾⣿⣿⣿⢿⣻⡽⣞⣽⡳⣾⣹⡟⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢰⢇⡄⢁⠒⠘⡇⠀⠀⠀⠀⠀⠀⢸⠀⠀⠀⠀⠀⢿⠀⡌⠱⡈⠱⡈⢆⠉⠆⡌⢢⠉⢆⡑⠌⢢⠑⢢⠐
  ⣯⡽⣞⡿⡽⣾⣹⠾⣽⢾⣹⢿⣿⣶⣝⣿⣿⠀⢀⣾⣿⡿⢟⣛⣥⣶⣿⣿⣿⣿⣿⣻⡽⢾⣹⣞⣳⠿⣼⡻⣵⡟⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⠈⡐⠈⠔⠁⡇⠀⠀⠀⠀⠀⠀⠘⡇⠀⠀⠀⠀⢺⠐⡨⢐⠡⡑⠨⢄⡉⠒⡌⠤⢉⠆⡘⠌⢢⠉⢆⠩
  ⣷⣻⡽⢾⣽⡳⢯⡿⡽⡾⣽⡞⣿⣿⣿⣿⣿⣤⣞⣯⣵⣾⣿⣿⣿⣿⣿⣿⣛⣷⣣⢷⣻⢯⣷⣫⣽⢻⣳⢟⡟⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⡾⣬⣄⣩⠤⣌⡇⠀⠀⠀⠀⠀⠀⠀⢧⠀⠀⠀⠀⢸⣠⣁⢦⣁⣆⣑⣂⣌⣡⣂⣅⣊⣤⣡⢎⣤⣉⢦⣡
  `
export const mailTo = `mailto:colin.james.rosati@gmail.com?subject=Hi Colin 👋&body=Dearest Pal Colin,%0D%0A%0D%0A I'm contacting you because theres a really excited oppurtunity I'd like to tell you about.%0D%0A You are the first person I thought about contacting!%0D%0A%0D%0A${mailBody}%0D%0A%0D%0A PS I like your website!`